

export default {
    state: {
        user: null,
        token: null
    },

    loadState: function(state) {
        this.state = state;
    },
    clear: function() {
        this.state.user = null;
        this.state.token = null;
        localStorage.removeItem('state');
    },




    setToken: function (token) {
        this.state.token = token
        localStorage.state = JSON.stringify(this.state)
    },
    getToken: function() {
        return this.state.token;
    },
    gedDecodedToken: function() {
        if (!this.state.token) return null;

        return JSON.parse(atob(this.state.token.split('.')[1]));
    },

    setUser: function (user) {
        this.state.user = user
        localStorage.state = JSON.stringify(this.state)
    },
    getUser: function() {
        return this.state.user;
    },

}
