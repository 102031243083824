<template>

    <div id="navigation">


        <nav id="navigation" >
                <div class="left">
                    <router-link to="/">Home</router-link> |
                    <router-link to="/about">About</router-link>
                </div>

                <div class="right" v-if="user">
                    <span class="welcome"> {{welcomePhrase}}</span> |
                    <router-link to="/posts">Entries</router-link> |
                    <router-link to="/new">New</router-link> |
                    <a @click="handleLogout()" href="#">Log out</a>
                </div>
                <div class="right" v-else>
                    <router-link to="/login" v-if="!user">Login</router-link>
                </div>


        </nav>


    </div>



</template>

<script>


export default {
    name: 'Navigation',
    components: {

    },
    props: {
        user: Object
    },
    data() {
        return {

        }
    },
    computed: {
        welcomePhrase: function() {
            let name = (this.user ? this.user.name : "");
            let greetings = [
                "🇪🇸 Hola",
                "🇫🇷 Bonjour",
                "🇮🇷 سلام",
                "🇯🇴 مرحبا",
                "🇮🇹 Ciao",
                "🇨🇳 你好",
                "🇱🇹 Labas",
                "🎗 Hola",
                "🇳🇴 Hallo",
                "👋",
            ];
            let greeting = greetings[Math.floor(Math.random() * greetings.length)]

            return greeting + " " + name;
        }
    },
    mounted() {

    },

    methods: {

        handleLogout() {
            this.$store.clear()
            this.$router.replace({ name: "home" });
        }

    }


}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>


nav {
    padding: 30px 0;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // flex-wrap: wrap;
}
nav .right {
    text-align: right;
}

nav a {
    font-weight: bold;
    color: #2c3e50;
}

nav a.router-link-exact-active {
    color: #42b983;
}


/* Portrait and Landscape */
/*@media only screen  and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {*/
// @media (max-width: 600px) {
//
//     nav {
//         padding: 30px 0;
//         text-align: left;
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//     }
//     nav div {
//         flex: 0 0 100%;
//     }
//     nav .right {
//         text-align: left;
//     }
//
// }


</style>
