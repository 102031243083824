export default {


    fontStackClass: function(code) {
        var face = "";
        switch (code) {
            case "zh": face = "zh"; break;
            case "ar": face = "ar"; break;
            case "ru": face = "ru"; break;
            default:   face = "lt"; break;

        }
        return `font-face-${face}`;
    }




}
