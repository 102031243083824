<template>
    <div id="app">

		<navigation :user="$store.getUser()" />

        <div class="wrapper">
            <router-view/>
        </div>

		<footer>
			<p class="text-muted">With love from Norway</p>
		</footer>



    </div>
</template>


<script>
import Navigation from '@/components/Navigation.vue'

export default {
    name: 'App',
	components: {
		Navigation
	},
    methods: {
        handleLogout() {
            this.$store.clear()
            this.$router.replace({ name: "login" });
        }
    },
    computed: {
        welcomePhrase: function() {

            return "Velkommen xxx";
        }
    }
}
</script>


<style lang="scss">

$default-typo: 'Avenir, Helvetica Neue, Helvetica Arial, sans-serif';

body, html {
	padding: 0;
	margin: 0;
}
body {
	font-family: unquote($default-typo);;
	font-smoothing: antialiased;
	color: #111;
	background-color: #fffff8;
}

#app {
	margin: auto;
	max-width: 40em;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

#app > .wrapper {
	flex: 1 0 auto;
}
#app > footer {
	text-align: center;
	padding-top: 5rem;
	padding-bottom: 1rem;
	flex-shrink: 0;
}



a {
	color: #111;
	text-decoration: none;
}
.wrapper a::after {
	content: "°";
}
.wrapper  a:hover {
	text-decoration: underline;
}
a.button {
	font-weight: bold;
}

.text-muted {
	opacity: 0.5;
}

.errors {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
	color: #B02E0C;
}






/* Portrait and Landscape */
/*@media only screen  and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {*/
@media (max-width: 600px) {

	#app {
		padding: 0 1em;
	}

}




@font-face {
	font-family: "et-book";
	src: url("./fonts/et-book/et-book-roman-line-figures/et-book-roman-line-figures.eot");
	src: url("./fonts/et-book/et-book-roman-line-figures/et-book-roman-line-figures.eot?#iefix") format("embedded-opentype"), url("./fonts/et-book/et-book-roman-line-figures/et-book-roman-line-figures.woff") format("woff"), url("./fonts/et-book/et-book-roman-line-figures/et-book-roman-line-figures.ttf") format("truetype"), url("./fonts/et-book/et-book-roman-line-figures/et-book-roman-line-figures.svg#etbookromanosf") format("svg");
	font-weight: normal;
	font-style: normal
}

@font-face {
	font-family: "et-book";
	src: url("./fonts/et-book/et-book-display-italic-old-style-figures/et-book-display-italic-old-style-figures.eot");
	src: url("./fonts/et-book/et-book-display-italic-old-style-figures/et-book-display-italic-old-style-figures.eot?#iefix") format("embedded-opentype"), url("./fonts/et-book/et-book-display-italic-old-style-figures/et-book-display-italic-old-style-figures.woff") format("woff"), url("./fonts/et-book/et-book-display-italic-old-style-figures/et-book-display-italic-old-style-figures.ttf") format("truetype"), url("./fonts/et-book/et-book-display-italic-old-style-figures/et-book-display-italic-old-style-figures.svg#etbookromanosf") format("svg");
	font-weight: normal;
	font-style: italic
}

@font-face {
	font-family: "et-book";
	src: url("./fonts/et-book/et-book-bold-line-figures/et-book-bold-line-figures.eot");
	src: url("./fonts/et-book/et-book-bold-line-figures/et-book-bold-line-figures.eot?#iefix") format("embedded-opentype"), url("./fonts/et-book/et-book-bold-line-figures/et-book-bold-line-figures.woff") format("woff"), url("./fonts/et-book/et-book-bold-line-figures/et-book-bold-line-figures.ttf") format("truetype"), url("./fonts/et-book/et-book-bold-line-figures/et-book-bold-line-figures.svg#etbookromanosf") format("svg");
	font-weight: bold;
	font-style: normal
}

@import url('https://fonts.googleapis.com/css2?family=Scheherazade:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Old+Standard+TT:ital,wght@0,400;0,700;1,400&display=swap');




%font-face-lt {
	font-family: 'et-book', unquote($default-typo);
}
%font-face-ar {
	font-family: 'Scheherazade', unquote($default-typo);
}
%font-face-zh {
	font-family: '华文细黑', 'STXihei', 'PingFang TC', '微软雅黑体', 'Microsoft YaHei New', '微软雅黑', 'Microsoft Yahei', '宋体', 'SimSun', unquote($default-typo);
}
%font-face-ru {
	font-family: 'Old Standard TT', unquote($default-typo);
}

.font-face-lt {
	@extend %font-face-lt;
	font-weight: 400;
	direction: ltr;
}
.font-face-ar {
	@extend %font-face-ar;
	font-weight: 400;
	direction: rtl;
}
.font-face-zh {
	@extend %font-face-zh;
	font-weight: 400;
}
.font-face-ru {
	@extend %font-face-ru;
	font-weight: 400;
}
</style>
