import Vue from 'vue'
import App from './App.vue'

import router from './router'





// Axios / Https config
import axios from 'axios'

Vue.prototype.$http = axios

const backendApi = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL
})
Vue.prototype.$api = backendApi



// Helpers
import helpers from '@/helpers'
Vue.prototype.$helpers = helpers


// Store
import store from '@/store'

if ( localStorage.hasOwnProperty("state") ) {
    store.state = JSON.parse(localStorage.state);
}
else {
    localStorage.state = JSON.stringify(store.state);
}


// Setup API auth
Vue.prototype.$store = store;
if (store.state.token) backendApi.defaults.headers.common['Authorization'] = 'Bearer ' +  store.state.token;



// Throw out users without auth on new data fetch
backendApi.interceptors.response.use(
    (response) => {
        return response;
    }, (error) => {
        if (error.response.status == 401 ) {
            store.clear();
            // ["/logginn", "/meldinn"].contains(router.currentRoute.path)
            if (router.currentRoute.meta.auth) router.replace('/login');
        }

        return Promise.reject(error);
    }
);

// Throw out users without auth on refresh
backendApi.get('/ping')
    .catch((response) => {});


// Router auth check
router.beforeEach((to, from, next) => {

    if (to.matched.some(record => record.meta.auth)) {
        if ( !store.state.user ) {
            next({
                path: '/login',
                params: { nextUrl: to.fullPath }
            })
        }
        else if ( Date.now()/1000 > store.gedDecodedToken().exp ) {
            store.clear();
            next({
                path: '/login',
                params: { nextUrl: to.fullPath }
            })
        }
        else {
            next()
        }
    }
    else if (store.getUser() && to.matched.some(record => record.path == "/login")) {
        next({
            path: '/posts'
        })
    }
    // else if (to.matched.some(record => record.meta.guest)) {
    //     if ( store.state.user ) {
    //         next({
    //             path: '/'
    //         })
    //     }
    //     else {
    //         next()
    //     }
    // }
    else {
        next()
    }
})


import CountryFlag from 'vue-country-flag'
Vue.component('country-flag', CountryFlag)

import LangFlag from '@/components/LangFlag'
Vue.component('lang-flag',LangFlag);


import Moment from 'vue-moment'
Vue.use(Moment);


Vue.config.productionTip = process.env.NODE_ENV === 'development'


new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
