import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './views/Home.vue'

Vue.use(VueRouter)


export default  new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        // Admin
        {
            path: '/login',
            name: 'login',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ './views/admin/Login.vue'),
            meta: {
                guest: true
            }
        },

        {
            path: '/posts',
            name: 'Posts',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ './views//admin/Posts.vue'),
            meta: {
                auth: true
            }
        },
        {
            path: '/new',
            name: 'New',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ './views/admin/New.vue'),
            meta: {
                auth: true
            }
        },




        {
            path: '/',
            name: 'Home',
            component: Home,
            meta: {
                guest: true
            }
        },
        {
            path: '/about',
            name: 'About',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
            meta: {
                guest: true
            }
        },


        


        // Profile
        {
            path: '/:username/',
            name: 'profile',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "transfer" */ './views/Profile.vue'),
            meta: {
                guest: true
            }
        },

        {
            path: '/:username/:key/',
            name: 'post',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "transfer" */ './views/Post.vue'),
            meta: {
                guest: true
            }
        },


    ]
})
