<template>


    <country-flag :country="country" :size='str' v-if="country" />



</template>

<script>


export default {
    name: 'LangFlag',
    components: {

    },
    props: {
        lang: String,
        size: String
    },
    data() {
        return {

        }
    },
    computed: {
        country: function() {
            let langs = {
                no: 'no',
                en: 'gb',

                ru: 'ru',
                ar: 'jo',
                zh: 'cn',
                ja: 'jp',

                es: 'es',
                it: 'it',
                fr: 'fr',
                pt: 'pt',
                ca: 'es-ca'
            }

            return langs[this.lang] ? langs[this.lang] : 'un';
        },
        str: function() {
            return this.size ? this.size : 'small';
        }
    },
    mounted() {

    },

    methods: {


    }


}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>




</style>
