<template>
    <div class="home">


        <h1>Welcome!</h1>

        <p>Links are marked by ° on this site</p>

        <section>

            <h2>Recent entries</h2>

            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Title</th>
                        <th>By</th>
                        <th>🌐</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="post in posts" :key="post.key">
                        <td>{{post.created_at | moment("D/M/Y")}}</td>
                        <td><router-link :to="{ name: 'post', params: { username: post.author.data.username, key: post.key }}">{{post.title}}</router-link> <span class="corrected">{{post.corrected ? "✅" : ""}}</span></td>
                        <td><router-link :to="{ name: 'profile', params: { username: post.author.data.username }}">{{post.author.data.username}}</router-link></td>
                        <td>
                            <lang-flag :lang="post.language" />
                        </td>
                    </tr>
                </tbody>
            </table>

        </section>



        <section>

            <h2>Recent users</h2>

            <table>
                <thead>
                    <tr>
                        <th>Joined</th>
                        <th>Username</th>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user in users" :key="user.username">
                        <td>{{user.created_at | moment("D/M/Y")}}</td>
                        <td><router-link :to="{ name: 'profile', params: { username: user.username }}">{{user.username}}</router-link></td>
                        <td>{{user.name}}</td>
                    </tr>
                </tbody>
            </table>

        </section>




    </div>
</template>

<script>
// @ is an alias to /src


export default {
    name: 'Home',
    components: {
    },
    data() {
        return {
            posts: [],
            users: []
        }
    },
    mounted() {

        this.$api.get('/posts/')
            .then((response) => {
                this.posts = response.data.data;
            })
            .catch((response) => {
                console.log(response);
            });

        this.$api.get('/users/')
            .then((response) => {
                this.users = response.data.data;
            })
            .catch((response) => {
                console.log(response);
            });

    },
    methods: {

    }
}
</script>


<style lang="scss" scoped>


section {
    margin-top: 5em;
}

table {
    width: 100%;
    text-align: left;
}
table tr td:last-child,
table tr th:last-child {
    text-align: right;
}

td a {
    vertical-align: middle;
}
.corrected {
    font-size: 0.4rem;
    vertical-align: middle;
}


</style>
